import moment from 'moment';
import * as R from 'ramda';
import 'moment/locale/nl';

const getOpeningHoursFromDuration = (date, openingHour) => {
  const openDuration = moment.duration(openingHour.openFrom);
  const openTime = date
    .set('hour', openDuration.hours())
    .set('minute', openDuration.minutes())
    .format('HH:mm');

  const closeDuration = moment.duration(openingHour.openUntil);
  const closeTime = date
    .set('hour', closeDuration.hours())
    .set('minute', closeDuration.minutes())
    .format('HH:mm');

  return `${openTime} - ${closeTime}`;
};

export const mapOpeningHours = (openingHours) => {
  if (!openingHours) return null;
  return openingHours.map((entry) => {
    const date = moment().day(entry.dayOfWeek);
    return {
      day: entry.dayOfWeek.toLowerCase(),
      hours: getOpeningHoursFromDuration(date, entry),
      isClosed: entry.isClosed,
    };
  });
};

export const mapPictures = (club) => {
  let pictures = club.photoUrl
    ? {
        [club.photoId]: {
          src: club.photoUrl,
          alt: club.name,
          id: club.photoId,
        },
      }
    : {};

  if (club.pictures) {
    const clubPictures = club.pictures
      ? club.pictures.map((picture) => {
          return {
            src: picture.photoUrl,
            alt: picture.alt || club.name,
            id: picture.photoId,
          };
        })
      : [];

    const clubPicturesByPhotoId = R.indexBy(R.prop('id'), clubPictures);

    pictures = {
      ...pictures,
      ...clubPicturesByPhotoId,
    };
  }

  return Object.values(pictures);
};

export const mapOpeningHoursExceptions = (openingHoursExceptions) => {
  // filter out entries > first week (to keep list short)
  const filteredExceptions = openingHoursExceptions.filter((entry) => {
    const weekFromNow = moment().add(7, 'days').endOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const dateFrom = moment(entry.dateFrom.split('+')[0]);
    return dateFrom.isBefore(weekFromNow) && dateFrom.isSameOrAfter(yesterday);
  });

  const formattedExceptions = filteredExceptions
    .flatMap((entry) => {
      moment.locale('nl');
      moment.updateLocale('nl', { monthsShort: moment.monthsShort('-MMM-') }); // Remove period (e.g. aug.) for short month names
      const isSingleDay = entry.dateFrom === entry.dateUntil;
      const dateFrom = moment(entry.dateFrom.split('+')[0]); // Hotfix remove timezone before convertion
      if (isSingleDay) {
        const formattedDate = dateFrom.format('dddd D MMM');
        return {
          date: entry.dateFrom,
          day: formattedDate,
          hours: getOpeningHoursFromDuration(dateFrom, entry), // the actual date doesn't matter, this function only manipulates the hours/minutes to use moment.js formatting
          isClosed: entry.isClosed,
        };
      }

      /*
      if the exception is not a single day, then we need to create entries for each of the days
      we know the start date and the end date,
      so we use a loop to create an array of entries with individual dates and set the hours
      then flatmap to get a single array
     */
      const dateUntil = moment(entry.dateUntil.split('+')[0]); // Hotfix remove timezone before convertion
      const dateEntry = moment(entry.dateFrom.split('+')[0]); // Hotfix remove timezone before convertion
      const exceptions = [];

      while (
        dateEntry.isBefore(dateUntil) ||
        dateEntry.isSame(dateUntil, 'day')
      ) {
        const formattedDate = dateEntry.format('dddd D MMM');
        const dateString =
          formattedDate[0].toUpperCase() + formattedDate.slice(1);

        exceptions.push({
          date: dateEntry.toISOString(),
          day: dateString,
          hours: getOpeningHoursFromDuration(dateFrom, entry), // the actual date doesn't matter, this function only manipulates the hours/minutes to use moment.js formatting
          isClosed: entry.isClosed,
        });
        dateEntry.add(1, 'days');
      }
      return exceptions;
    })
    .sort((a, b) => {
      return a.date.localeCompare(b.date);
    });

  return formattedExceptions;
};

const mapFacilities = (club) => {
  if (!club.facilities) return {};

  return club.facilities.reduce((facilities, facility) => {
    return {
      ...facilities,
      [facility.id]: facility.name,
    };
  }, {});
};

export default function mapClub(club) {
  // Adds a space between the letters and number at the end of the postal code if there is no space character present yet
  const formattedPostalCode =
    club.postalCode.slice(0, 4) +
    (club.postalCode.charAt(4) === ' ' ? '' : ' ') +
    club.postalCode.slice(-2);

  return {
    id: club.id,
    name: club.shortName,
    address: club.address,
    city: club.city.name,
    postalCode: formattedPostalCode,
    latitude: club.latitude,
    longitude: club.longitude,
    facilities: mapFacilities(club),
    pictures: mapPictures(club),
    openingHours: club.openingHours ? mapOpeningHours(club.openingHours) : [],
    openingHoursExceptions: club.openingHoursExceptions
      ? mapOpeningHoursExceptions(club.openingHoursExceptions)
      : [],
    phoneNumber: club.phoneNumber,
    emailAddress: club.email,
  };
}
