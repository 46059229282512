import { useMemo } from 'react'
import useResettableState from './use-resettable-state'

export default function useRequestState (initialIsLoading = false, inputs) {
  const [ { isLoading, error }, setRequestState ] = useResettableState({
    isLoading: initialIsLoading,
    error: undefined
  }, inputs)
  const [
    doSetLoading,
    doSetError
  ] = useMemo(
    () => [
      (_isLoading) => setRequestState({ isLoading: _isLoading }),
      (_error) => setRequestState({ isLoading: false, error: _error })
    ],
    [ setRequestState ]
  )
  return [
    isLoading,
    error,
    { doSetLoading, doSetError }
  ]
}
