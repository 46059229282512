import { DiscountDefinition } from '@features/pg-funnel/services/perfect-gym/types'
import { ACTIVE_FUNNELS } from './active-funnels'

type Company = string
type Funnel = string
type PaymentPlanId = number

interface ContractDiscountsData {
    [key: Company]: {
        [key: Funnel]: {
            [key: PaymentPlanId]: DiscountDefinition[]
        }
    }
}

export const production: ContractDiscountsData = {
  sportcity: {
    dagpas: {
      10610: [
        {
          contractDiscountDefinitionId: 1418
        }
      ],
      6888: [
        {
          contractDiscountDefinitionId: 670
        }
      ]
    },
    default: {
      10495: [
        {
          contractDiscountDefinitionId: 1419
        }
      ],
      10496: [
        {
          contractDiscountDefinitionId: 1296,
          parameters: {
            sinceInterval: 1,
            untilInterval: 6
          }
        },
        {
          contractDiscountDefinitionId: 1297,
          parameters: {
            sinceInterval: 7,
            untilInterval: 7
          }
        }
      ],
      10497: [
        {
          contractDiscountDefinitionId: 1298,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10498: [
        {
          contractDiscountDefinitionId: 1300,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10499: [
        {
          contractDiscountDefinitionId: 1301,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10502: [
        {
          contractDiscountDefinitionId: 1420
        }
      ],
      10503: [
        {
          contractDiscountDefinitionId: 1305,
          parameters: {
            sinceInterval: 1,
            untilInterval: 6
          }
        },
        {
          contractDiscountDefinitionId: 1306,
          parameters: {
            sinceInterval: 7,
            untilInterval: 7
          }
        }
      ],
      10504: [
        {
          contractDiscountDefinitionId: 1307,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10505: [
        {
          contractDiscountDefinitionId: 1308,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10506: [
        {
          contractDiscountDefinitionId: 1309,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10588: [ // SCV ClubOnly 1month 4-weeks Nov2023
        {
          contractDiscountDefinitionId: 1393
        }
      ],
      10589: [ // SCV ClubOnly 1month 4-weeks Nov2023 Cash
        {
          contractDiscountDefinitionId: 1394
        }
      ],
      10590: [ // SCV ClubOnly 6months 4-weeks Nov2023
        {
          contractDiscountDefinitionId: 1395,
          parameters: {
            sinceInterval: 1,
            untilInterval: 6
          }
        },
        {
          contractDiscountDefinitionId: 1396,
          parameters: {
            sinceInterval: 7,
            untilInterval: 7
          }
        }
      ],
      10591: [ // SCV ClubOnly 6months 6months Nov2023
        {
          contractDiscountDefinitionId: 1397,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10592: [ // SCV ClubOnly 6months 6months Nov2023 Cash
        {
          contractDiscountDefinitionId: 1398,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10593: [ // SCV ClubOnly 1year 4-weeks Nov2023
        {
          contractDiscountDefinitionId: 1399,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10594: [ // SCV ClubOnly 1year 1year Nov2023
        {
          contractDiscountDefinitionId: 1400,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10595: [ // SCV ClubOnly 1year 1year Nov2023 Cash
        {
          contractDiscountDefinitionId: 1401,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10599: [// SCV 1month 4-weeks Nov2023
        {
          contractDiscountDefinitionId: 1405
        }
      ],
      10600: [// SCV 1month 4-weeks Nov2023 Cash
        {
          contractDiscountDefinitionId: 1406
        }
      ],
      10601: [ // SCV 6months 4-weeks Nov2023
        {
          contractDiscountDefinitionId: 1407,
          parameters: {
            sinceInterval: 1,
            untilInterval: 6
          }
        },
        {
          contractDiscountDefinitionId: 1408,
          parameters: {
            sinceInterval: 7,
            untilInterval: 7
          }
        }
      ],
      10602: [ // SCV 6months 6months Nov2023
        {
          contractDiscountDefinitionId: 1409,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10603: [ // SCV 6months 6months Nov2023 Cash
        {
          contractDiscountDefinitionId: 1410,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10604: [ // SCV 1year 4-weeks Nov2023
        {
          contractDiscountDefinitionId: 1411,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10605: [ // SCV 1year 1year Nov2023
        {
          contractDiscountDefinitionId: 1412,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10606: [ // SCV 1year 1year Nov2023
        {
          contractDiscountDefinitionId: 1413,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ]
    },
    'back-to-sport': {
      6882: [
        {
          contractDiscountDefinitionId: 664
        }
      ],
      6883: [
        {
          contractDiscountDefinitionId: 664
        }
      ]
    },
    student: {
      10596: [ // SCV ClubOnly Student 1year 4-weeks Nov2023
        {
          contractDiscountDefinitionId: 1402,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10597: [ // SCV ClubOnly Student 1year 1year Nov2023
        {
          contractDiscountDefinitionId: 1403,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10598: [ // SCV ClubOnly Student 1year 1year Nov2023 Cash
        {
          contractDiscountDefinitionId: 1404,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10607: [ // SCV Student 1year 4-weeks Nov2023
        {
          contractDiscountDefinitionId: 1414,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10608: [ // SCV Student 1year 1year Nov2023
        {
          contractDiscountDefinitionId: 1415,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10609: [ // SCV Student 1year 1year Nov2023 Cash
        {
          contractDiscountDefinitionId: 1416,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10500: [ // ClubOnly CityFit Student 1year 4-weeks
        {
          contractDiscountDefinitionId: 1302,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10501: [ // ClubOnly CityFit Student 1year 1year
        {
          contractDiscountDefinitionId: 1303,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10507: [ // CityFit Student 1year 4-weeks
        {
          contractDiscountDefinitionId: 1310,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10508: [ // CityFit Student 1year 1year
        {
          contractDiscountDefinitionId: 1311,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ]
    },
    [ACTIVE_FUNNELS.sportcity.CORPORATE]: {
      10519: [
        {
          contractDiscountDefinitionId: 1322
        }
      ],
      10520: [
        {
          contractDiscountDefinitionId: 1324
        }
      ],
      10521: [
        {
          contractDiscountDefinitionId: 1326
        }
      ],
      10522: [
        {
          contractDiscountDefinitionId: 1328
        }
      ]
    }
  },
  sportcitypremium: {
    'cornelis-schuyt': {
      22: [
        {
          contractDiscountDefinitionId: 160
        }
      ],
      23: [
        {
          contractDiscountDefinitionId: 160
        }
      ],
      24: [
        {
          contractDiscountDefinitionId: 160
        }
      ]
    }
  }
}

/**
 * PG changed the data to be the same as production,
 * we can remove this config althogether and use 'production' for all environments
 */
export const staging: ContractDiscountsData = {
  sportcity: {
    dagpas: {
      10602: [
        {
          contractDiscountDefinitionId: 1409
        }
      ],
      6888: [
        {
          contractDiscountDefinitionId: 670
        }
      ]
    },
    student: {
      10593: [
        {
          contractDiscountDefinitionId: 1398,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10594: [
        {
          contractDiscountDefinitionId: 1399,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10600: [
        {
          contractDiscountDefinitionId: 1406,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10601: [
        {
          contractDiscountDefinitionId: 1407,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10500: [
        {
          contractDiscountDefinitionId: 1302,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10501: [
        {
          contractDiscountDefinitionId: 1303,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10507: [
        {
          contractDiscountDefinitionId: 1310,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10508: [
        {
          contractDiscountDefinitionId: 1311,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ]
    },
    default: {
      10588: [
        {
          contractDiscountDefinitionId: 1392
        }
      ],
      10589: [
        {
          contractDiscountDefinitionId: 1393,
          parameters: {
            sinceInterval: 1,
            untilInterval: 6
          }
        },
        {
          contractDiscountDefinitionId: 1394,
          parameters: {
            sinceInterval: 7,
            untilInterval: 7
          }
        }
      ],
      10590: [
        {
          contractDiscountDefinitionId: 1395,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10591: [
        {
          contractDiscountDefinitionId: 1396,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10592: [
        {
          contractDiscountDefinitionId: 1397,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10596: [
        {
          contractDiscountDefinitionId: 1401,
          parameters: {
            sinceInterval: 1,
            untilInterval: 6
          }
        },
        {
          contractDiscountDefinitionId: 1402,
          parameters: {
            sinceInterval: 7,
            untilInterval: 7
          }
        }
      ],
      10495: [
        {
          contractDiscountDefinitionId: 1410
        }
      ],
      10496: [
        {
          contractDiscountDefinitionId: 1296,
          parameters: {
            sinceInterval: 1,
            untilInterval: 6
          }
        },
        {
          contractDiscountDefinitionId: 1297,
          parameters: {
            sinceInterval: 7,
            untilInterval: 7
          }
        }
      ],
      10497: [
        {
          contractDiscountDefinitionId: 1298,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10498: [
        {
          contractDiscountDefinitionId: 1300,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10499: [
        {
          contractDiscountDefinitionId: 1301,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10502: [
        {
          contractDiscountDefinitionId: 1411
        }
      ],
      10503: [
        {
          contractDiscountDefinitionId: 1305,
          parameters: {
            sinceInterval: 1,
            untilInterval: 6
          }
        },
        {
          contractDiscountDefinitionId: 1306,
          parameters: {
            sinceInterval: 7,
            untilInterval: 7
          }
        }
      ],
      10504: [
        {
          contractDiscountDefinitionId: 1307,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ],
      10505: [
        {
          contractDiscountDefinitionId: 1308,
          parameters: {
            sinceInterval: 1,
            untilInterval: 13
          }
        }
      ],
      10506: [
        {
          contractDiscountDefinitionId: 1309,
          parameters: {
            sinceInterval: 1,
            untilInterval: 1
          }
        }
      ]
    }
  }
}

export default {
  production,
  staging
}
