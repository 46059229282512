import {
  useCallback,
  useMemo,
  useRef
} from 'react'
import { produce } from 'immer'
import useStableCallback from './use-stable-callback'
import useForceRerender from './use-force-rerender'

export default function useResettableReducer (reducer, initialState, deps = []) {
  const stateRef = useRef()
  useMemo(() => {
    stateRef.current = initialState
  }, deps)
  const forceRerender = useForceRerender()
  reducer = useStableCallback(reducer, [ reducer ])
  const dispatch = useCallback((...args) => {
    const nextState = produce(
      stateRef.current,
      draft => reducer(draft, ...args)
    )
    if (nextState !== stateRef.current) {
      stateRef.current = nextState
      forceRerender()
    }
  }, [])

  return [ stateRef.current, dispatch ]
}
