import styles from '@features/pg-funnel/components/content-items/funnel-usp-block/index.module.scss';
import { Layout } from '@features/shared/components/layout';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import { Body, FontColor, H4 } from 'src/features/shared/components/typography';

interface IListAlphabeticalProps {
  itemsByAlphabet: Map<string, string[]>;
}

export const ListAlphabetical = ({
  itemsByAlphabet,
}: IListAlphabeticalProps) => {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isSmall =
    currentBreakpoint === breakpoints.xs.name ||
    currentBreakpoint === breakpoints.sm.name;

  return (
    <>
      {Array.from(itemsByAlphabet).map(([letter, names]) => (
        <AlphabeticalList
          key={`club names ${letter}`}
          letter={letter}
          names={names}
          isSmall={isSmall}
        />
      ))}
    </>
  );
};

interface IAlphabeticalListProps {
  letter: string;
  names: string[];
  isSmall: boolean;
}

const AlphabeticalList = ({
  letter,
  names,
  isSmall,
}: IAlphabeticalListProps) => {
  const firstColumn = isSmall
    ? names
    : names.slice(0, Math.ceil(names.length / 2));
  const secondColumn = isSmall ? [] : names.slice(Math.ceil(names.length / 2));

  return (
    <ul>
      <H4 className={styles.heading}>{letter}</H4>
      <Layout.Row>
        <Layout.Col columns="md:4">
          <ListColumn names={firstColumn} />
        </Layout.Col>
        {secondColumn.length > 0 && (
          <Layout.Col columns="md:4" offset="md:2">
            <ListColumn names={secondColumn} />
          </Layout.Col>
        )}
      </Layout.Row>
    </ul>
  );
};

interface IListColumnProps {
  names: string[];
}

const ListColumn = ({ names }: IListColumnProps) => (
  <>
    {names.map((name) => (
      <li key={`Club ${name}`}>
        <Body color={FontColor.Neutral02}>{name}</Body>
      </li>
    ))}
  </>
);
