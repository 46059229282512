import { createReducer } from '@touchtribe/redux-helpers'
import { produce } from 'immer'

export default function createImmerReducer (domain, handlers, initialState) {
  const reducer = createReducer(domain, handlers, initialState)
  const immerReducer = produce(reducer)
  const wrappedReducer = (state = initialState, action) => immerReducer(state, action)
  wrappedReducer.toString = reducer.toString
  wrappedReducer.isImmerReducer = true
  return wrappedReducer
}
