import { Club } from '@features/pg-funnel/services/perfect-gym/types'
import { mapPictures } from 'src/services/perfect-gym/utils/map-club'

export interface Picture {
  src: string
  id: number
  alt: string
}

export interface MappedClub extends Pick<Club, 'id' | 'address' | 'postalCode' | 'latitude' | 'longitude' | 'phoneNumber' > {
  name: string
  city: string
  emailAddress: string
  pictures: Array<Picture | undefined>
}

export default function mapClub (club: Club): MappedClub {
  // Adds a space between the letters and number at the end of the postal code if there is no space character present yet
  const formattedPostalCode = club.postalCode.slice(0, 4) + (club.postalCode.charAt(4) === ' ' ? '' : ' ') + club.postalCode.slice(-2)

  return {
    id: club.id,
    name: club.shortName,
    address: club.address,
    city: club.city.name,
    postalCode: formattedPostalCode,
    latitude: club.latitude,
    longitude: club.longitude,
    // facilities: mapFacilities(club), // todo: verify this isn't used
    pictures: mapPictures(club),
    // openingHours: mapOpeningHours(club), todo: verify isn't returned from PG
    phoneNumber: club.phoneNumber,
    emailAddress: club.email
  }
}
