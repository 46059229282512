import { Document } from '@contentful/rich-text-types';
import { EntryFields } from 'contentful';
import { isAvailable } from 'src/utils/is-available';

export function isRichText(node: unknown): node is EntryFields.RichText {
  return (
    isAvailable(node) &&
    (node as Document).nodeType === 'document' &&
    Array.isArray((node as Document).content)
  );
}
