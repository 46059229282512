import { Club as IClub } from '@features/pg-funnel/services/perfect-gym/types';
import { ClubWithCmsData } from '@features/pg-funnel/utils/funnel';
import { IGeolocation } from '@models/IGeolocation';
import { getDistance } from 'geolib';
import { Club } from 'src/features/shared/types/Club';

export function addDistanceToClubs<T>(
  clubs: IClub[] | ClubWithCmsData[] | Club.Electrolyte[],
  geoLocation: IGeolocation,
  propName = 'geoDistance',
): T[] {
  return clubs.map((club) => {
    if (club.latitude && club.longitude) {
      return {
        ...club,
        [propName]: getDistance(geoLocation, club, 1),
      };
    }
  }) as T[];
}
