import { SignUpData } from '@features/pg-funnel/services/perfect-gym/types'
import { MappedPaymentPlan } from '@features/pg-funnel/services/perfect-gym/mappers/map-payment-plans'
import { FormValues } from '@features/pg-funnel/types'
import { Voucher } from '@features/pg-funnel/services/perfect-gym/mappers/map-voucher'
import { getContractDiscountDefinitions } from '@features/pg-funnel/services/perfect-gym/utils'
import { AnalyticsQueries } from '@features/pg-funnel/utils/analytics'
import { AnalyticsMeasurement } from '@features/pg-funnel/types/analytics'
import theme from 'src/config/theme'

// Transforms to uppercase and removes spaces from postalCode so perfectGym accepts it
const sanitizePostalCode = (postalCode: string) => postalCode.toUpperCase().replace(/\s/g, '')

export type FormData = SignUpData & {
  signupConfirmationUrl: string | undefined
  signupConfirmationJson: AnalyticsMeasurement | undefined
  websiteHostname: string;
}

export const mapFormData = (formValues: FormValues, selectedPaymentPlan: MappedPaymentPlan, funnelSlug: string, activeVoucher: Voucher | null, skipPaymentValidation: boolean, analyticsQueries?: AnalyticsQueries): FormData => {
  const additionalAddressLine = formValues.houseNumberAddition ? `${formValues.houseNumber} ${formValues.houseNumberAddition}` : `${formValues.houseNumber}`

  /*
    For some discounts we need to hardcode the interval they apply for. For others, we can just pass the discountIds
   */
  const contractDiscountsData = getContractDiscountDefinitions({
    paymentPlan: selectedPaymentPlan,
    funnelSlug,
    voucher: activeVoucher
  })

  return {
    funnel: funnelSlug,
    homeClubId: Number(formValues.clubId),
    personalData: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      birthDate: formValues.birthDate,
      phoneNumber: formValues.phoneNumber,
      email: formValues.emailAddress,
      sex: formValues.gender
    },
    addressData: {
      street: formValues.street,
      additionalAddressLine,
      postalCode: sanitizePostalCode(formValues.postalCode),
      cityName: formValues.city,
      countrySymbol: 'NL'
    },
    contractData: {
      voucherCode: activeVoucher?.summary.voucher.code || formValues.accessCode, // we pass the voucherCode so Electrolyte can keep track of how many times it's been used in signups
      paymentPlanId: Number(selectedPaymentPlan?.id),
      contractDiscountsData,
      signUpDate: new Date(),
      startDate: formValues.startDate || new Date()
    },
    addons: formValues.addons,
    studentNumber: formValues.studentNumber,
    skipPaymentValidation,
    signupConfirmationUrl: analyticsQueries?.ua,
    signupConfirmationJson: analyticsQueries?.ga,
    websiteHostname: window.location.hostname || theme.hostname
  }
}
