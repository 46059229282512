import { ProxyClient } from '@services/proxy/client';
import {
  Contract,
  SignUpData,
} from '@features/pg-funnel/services/perfect-gym/types';
import { ContractParams } from '@features/pg-funnel/services/perfect-gym';
import { mapVoucher } from '@features/pg-funnel/services/perfect-gym/mappers/map-voucher';
import { mapAccessCodeStatus } from '../perfect-gym/mappers/map-access-code-status';
import axios from 'axios';

export async function getContract({
  clubId,
  paymentPlanId,
  contractDiscountsData,
}: ContractParams) {
  return await ProxyClient.post<Contract>(
    `/perfect-gym/contract/${clubId}/${paymentPlanId}`,
    contractDiscountsData,
  );
}

export const ERROR_CODES = ['blacklisted', 'frozen_contract', 'open_contract'];
export const USER_ERROR_CODES = ['payment_failed', 'validation_error'];

export interface ISignupError {
  code?: (typeof ERROR_CODES)[number] | (typeof USER_ERROR_CODES)[number];
  message?: string;
}

interface IPostSignupData {
  paymentLink: string;
  error?: ISignupError;
}

interface IPostSignupResponse {
  data: IPostSignupData | null;
  statusCode: number;
}

export async function postSignup(
  signUpData: SignUpData,
): Promise<IPostSignupResponse> {
  try {
    const { data, status } = await ProxyClient.post<IPostSignupData>(
      '/perfect-gym/signup',
      signUpData,
    );
    return { data, statusCode: status };
  } catch (error) {
    let data;
    let status;

    if (axios.isAxiosError(error)) {
      data = error.response?.data;
      status = error.response?.status;
    }

    return { data: data, statusCode: status ?? 500 };
  }
}

export async function getVoucher(contractData: ContractParams) {
  const { data } = await ProxyClient.post(
    '/perfect-gym/voucher-code',
    contractData,
  );
  return mapVoucher(data);
}

export async function getAccessCodeStatus(code: string) {
  const { data } = await ProxyClient.get(`/perfect-gym/access-code/${code}`);

  return mapAccessCodeStatus(data);
}
