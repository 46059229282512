import { ACTIVE_FUNNELS } from './active-funnels';

type SubscriptionType = string;
type Company = string;
type ClubId = number;
type FromPrice = number;
type Link = string;
type Slug = string;

export interface ClubsSubscriptionData {
  clubs: ClubId[];
  price: FromPrice;
  link?: Link;
  promo?: string;
  hidePriceDescription?: boolean;
}

export interface ClubDataBySubscription {
  [key: SubscriptionType]: ClubsSubscriptionData;
}

export interface ClubIdBySubscriptionTypeProps {
  [key: Company]: {
    [key: Slug]: ClubDataBySubscription;
  };
}

const CORE_CLUBS = [
  56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
  75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 89, 90, 91, 93, 94, 95,
  96, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 111, 112, 113,
  114, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129,
  130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144,
  145, 146, 147, 152,
];
const PLUS_CLUBS = [
  29, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
  49, 50, 51, 52, 53,
];

export const production: ClubIdBySubscriptionTypeProps = {
  sportcity: {
    student: {
      core: {
        clubs: [
          147, 108, 152, 113, 146, 120, 89, 110, 107, 61, 63, 65, 66, 58, 54,
          62, 57, 59, 56, 55, 64, 109, 140, 98, 105, 87, 133, 94, 141, 79, 96,
          72, 77, 144, 78, 75, 73, 76, 111, 84, 138, 134, 117, 135, 136, 143,
          103, 125, 119, 115, 122, 86, 121, 91, 80, 112, 118, 102, 90, 123, 129,
          114, 127, 83, 67, 95, 124, 81, 130, 100, 93, 131, 101, 142, 137, 128,
          150, 126, 132, 139, 106, 70, 69, 116, 68, 82, 74, 60, 71, 145, 104,
          85, 99,
        ],
        price: 22.99,
        link: '/checkout/student',
      },
      plus: {
        clubs: [
          53, 31, 46, 38, 39, 34, 50, 36, 40, 49, 52, 37, 47, 42, 33, 29, 48,
          32, 43, 35, 41, 51, 44, 45,
        ],
        price: 39.99,
        link: '/checkout/student',
      },
    },
    default: {
      core: {
        clubs: CORE_CLUBS,
        price: 29.99,
        link: '/checkout',
      },
      plus: {
        clubs: PLUS_CLUBS,
        price: 49.99,
        link: '/checkout',
      },
      premium: {
        clubs: [28],
        price: 89,
        link: '/funnel/cornelis-schuyt',
      },
    },
    dagpas: {
      core: {
        clubs: CORE_CLUBS,
        price: 12.99,
        hidePriceDescription: true,
      },
      plus: {
        clubs: PLUS_CLUBS,
        price: 19.99,
        hidePriceDescription: true,
      },
    },
    corporate: {
      core: {
        clubs: CORE_CLUBS,
        price: 29.99,
        hidePriceDescription: true,
      },
      plus: {
        clubs: PLUS_CLUBS,
        price: 54.99,
        hidePriceDescription: true,
      },
    },
    just: {
      core: {
        clubs: CORE_CLUBS,
        price: 17.5,
        hidePriceDescription: true,
      },
      plus: {
        clubs: PLUS_CLUBS,
        price: 32.5,
        hidePriceDescription: true,
      },
    },
    ohra: {
      core: {
        clubs: CORE_CLUBS,
        price: 19.99,
        hidePriceDescription: true,
      },
      plus: {
        clubs: PLUS_CLUBS,
        price: 35.99,
        hidePriceDescription: true,
      },
    },
    cz: {
      core: {
        clubs: CORE_CLUBS,
        price: 19.99,
        hidePriceDescription: true,
      },
      plus: {
        clubs: PLUS_CLUBS,
        price: 35.99,
        hidePriceDescription: true,
      },
    },
    nn: {
      core: {
        clubs: CORE_CLUBS,
        price: 19.99,
        hidePriceDescription: true,
      },
      plus: {
        clubs: PLUS_CLUBS,
        price: 35.99,
        hidePriceDescription: true,
      },
    },
    [ACTIVE_FUNNELS.sportcity.AHOLD]: {
      core: {
        clubs: CORE_CLUBS,
        price: 17.5,
        hidePriceDescription: true,
      },
      plus: {
        clubs: PLUS_CLUBS,
        price: 32.5,
        hidePriceDescription: true,
      },
    },
    'mind-us': {
      all: {
        clubs: [
          147, 108, 152, 113, 146, 120, 89, 110, 107, 61, 63, 65, 66, 58, 54,
          62, 57, 59, 56, 55, 64, 109, 140, 98, 105, 87, 133, 94, 141, 79, 96,
          72, 77, 144, 78, 75, 73, 76, 111, 84, 138, 134, 117, 135, 136, 143,
          103, 125, 119, 115, 122, 86, 121, 91, 80, 112, 118, 102, 90, 123, 129,
          114, 127, 83, 67, 95, 124, 81, 130, 100, 93, 131, 101, 142, 137, 128,
          150, 126, 132, 139, 106, 70, 69, 116, 68, 82, 74, 60, 71, 145, 104,
          85, 99, 29, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
          45, 46, 47, 48, 49, 50, 51, 52, 53,
        ],
        price: 0.0,
        hidePriceDescription: true,
      },
    },
  },
  sportcitypremium: {
    default: {
      premium: {
        clubs: [28],
        price: 89,
        link: '/funnel/cornelis-schuyt',
      },
    },
  },
};

export const staging: ClubIdBySubscriptionTypeProps = production;

export default {
  staging,
  production,
};
