import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const noop = () => {}

export default function useBoundDispatch (actionCreator) {
  const doDispatch = useDispatch()
  return useCallback((...args) => {
    const result = doDispatch(actionCreator(...args))
    if (typeof result.catch === 'function') {
      result.catch(noop)
    }
    return result
  }, [ actionCreator ])
}
