import { Document } from '@contentful/rich-text-types';
import { isAvailable } from 'src/utils/is-available';

export function isDocumentNode(node: unknown): node is Document {
  return (
    isAvailable(node) &&
    (node as Document).nodeType === 'document' &&
    Array.isArray((node as Document).content)
  );
}
