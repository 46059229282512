import classNames from 'classnames';
import React from 'react';
import styles from './RichText.module.scss';

interface RichTextProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isLight?: boolean;
  className?: string;
}

export function RichText({
  children,
  isLight,
  className,
  ...props
}: RichTextProps) {
  return (
    <div
      className={classNames(
        [styles.container, { [styles.light]: isLight }],
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
