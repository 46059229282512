import classNames from 'classnames';
import styles from './index.module.scss';
import Icons from 'features/shared/components/icons';
import { InputGroup } from 'src/features/shared/components/input-group';
import { Body } from 'src/features/shared/components/typography';
import { DescriptionColor } from 'src/features/shared/components/input-group/InputGroup';
import { ComponentProps } from 'react';
import { useIntl } from 'src/domains/i18n';

interface IInputProps extends ComponentProps<'input'> {
  label?: string;
  description?: string;
  error?: string;
  isSearch?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  descriptionColor?: DescriptionColor;
  isAlternative?: boolean;
  isLight?: boolean;
  onClear?: () => void;
  type?: string;
  hasVisualIndicator?: boolean;
}

export function Input({
  id,
  error,
  value,
  isSearch,
  onClear,
  type = 'text',
  label,
  description,
  descriptionColor,
  isLight,
  isAlternative,
  hasVisualIndicator,
  className,
  ...props
}: IInputProps) {
  const { formatMessage } = useIntl();
  const isValid = !!value && !error; // Check if there is already a value and no error (pre-filled) or when on touched, to show a green checkmark on the input

  return (
    <InputGroup
      label={label}
      id={id}
      error={error}
      descriptionColor={descriptionColor}
      hasVisualIndicator={hasVisualIndicator}
      isValid={isValid}
      description={description}
    >
      <div className={styles.container}>
        <Body component="span">
          <input
            id={id}
            type={type}
            value={value}
            className={classNames(
              styles.input,
              {
                [styles.error]: error,
                [styles.search]: isSearch,
                [styles.light]: isLight,
                [styles.alternative]: isAlternative,
              },
              className,
            )}
            {...props}
          />
        </Body>
        {isSearch &&
          (!value ? null : (
            <button
              id="clear-search"
              type="button"
              className={styles['search-button']}
              onClick={onClear}
              aria-label={formatMessage('input.clear')}
            >
              {isAlternative ? (
                <Icons.InputCancel className={styles.icon} />
              ) : (
                <Icons.Cross className={styles.icon} />
              )}
            </button>
          ))}
      </div>
    </InputGroup>
  );
}
