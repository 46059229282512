import {
  Discount, DiscountDefinition
} from '@features/pg-funnel/services/perfect-gym/types'
import { CreateEntityById, getEntityById } from '@features/pg-funnel/utils'
import {
  mapPaymentPlan,
  MappedPaymentPlan
} from '@features/pg-funnel/services/perfect-gym/mappers/map-payment-plans'
import { MappedClub } from '@features/pg-funnel/services/perfect-gym/mappers/map-club'
import { mapClubs } from '@features/pg-funnel/services/perfect-gym/mappers/map-clubs'
import theme from '@config/theme'
import ContractDiscountsData from '@features/pg-funnel/config/contract-discounts-data'
import { Voucher } from '@features/pg-funnel/services/perfect-gym/mappers/map-voucher'

export type PgPaymentPlansById = CreateEntityById<MappedPaymentPlan>
export type PgClubsById = CreateEntityById<MappedClub>

interface ClubsAndPaymentPlans {
  clubsById: PgClubsById
  paymentPlansById: PgPaymentPlansById
}

export const getClubsAndPaymentPlansFromDiscounts = (discounts: Discount[]): ClubsAndPaymentPlans => {
  return discounts.reduce((acc, discount) => {
    const mappedClubs = mapClubs(discount.clubs)
    const clubsById = getEntityById(mappedClubs)
    const mappedPaymentPlans = discount.paymentPlans.map((paymentPlan): MappedPaymentPlan => {
      // find all discounts that are applicable to this payment plan and return an array of their ids
      const applicableDiscountIds = discounts.map(discount => discount.paymentPlans.find(plan => plan.id === paymentPlan.id) && discount.id).filter(Boolean)
      // then add them to the rest of the paymentPlan
      return mapPaymentPlan({ ...paymentPlan, discountIds: applicableDiscountIds as number[] })
    })
    const paymentPlansById = getEntityById(mappedPaymentPlans)

    return {
      ...acc,
      clubsById: {
        ...acc.clubsById,
        ...clubsById
      },
      paymentPlansById: {
        ...acc.paymentPlansById,
        ...paymentPlansById
      }
    }
  }, {
    clubsById: {},
    paymentPlansById: {}
  })
}

interface ContractDiscountParameters {
  paymentPlan: MappedPaymentPlan
  funnelSlug: string
  voucher?: Voucher | null
}

/*
  Discounts often apply only for a certain interval. To find the intervals, we check our config or take the data from a Voucher.
 */
export const getContractDiscountDefinitions = ({
  paymentPlan,
  funnelSlug,
  voucher
}: ContractDiscountParameters): DiscountDefinition[] => {
  const perfectGymUrl = process.env.NEXT_PUBLIC_PG_URL
  const useStagingData = perfectGymUrl?.includes('test')
  const themeName: string = theme.name
  const contractDiscountsConfig = useStagingData
    ? ContractDiscountsData.staging[themeName]?.[funnelSlug]?.[paymentPlan.id]
    : ContractDiscountsData.production[themeName]?.[funnelSlug]?.[paymentPlan.id]
  const contractDiscountsFromPaymentPlan = paymentPlan.discountIds.map(id => ({ contractDiscountDefinitionId: id }))
  const contractDiscountsData = contractDiscountsConfig || contractDiscountsFromPaymentPlan
  const voucherDiscountData: DiscountDefinition | undefined = voucher ? {
    contractDiscountDefinitionId: voucher.summary.voucher.discountId,
    ...(voucher.summary.voucher?.untilInterval ? {
      parameters: {
        sinceInterval: voucher.summary.voucher?.sinceInterval,
        untilInterval: voucher.summary.voucher?.untilInterval
      }
    } : {})
  } : undefined

  return [ ...contractDiscountsData, voucherDiscountData ].filter(Boolean) as DiscountDefinition[]
}
