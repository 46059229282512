import {
  Discount,
  PaymentPlan,
  SignUpData,
} from '@features/pg-funnel/services/perfect-gym/types';
import { IClubContentPage } from 'src/features/shared/contentful/types/IClubContentPage';
import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';
import { Club } from 'src/features/shared/types/Club';
import { IJobVacancy } from 'src/features/shared/types/IJobVacancy';
import { ProxyClient } from './client';

export async function getClubs() {
  return await ProxyClient.get('/clubs');
}

export async function getClubById(id: number | string) {
  return await ProxyClient.get<Club.MapCard>(`/clubs/${id}`);
}

export async function getPage(slug: string) {
  return await ProxyClient.get<IClubContentPage | IContentPage>(
    `/pages/${slug}`,
  );
}

export async function getClubOpeningHoursByClubId(id: number | string) {
  return await ProxyClient.get(`/perfect-gym/club-opening-hours/${id}`);
}

export async function getClubOpeningHoursExceptionsByClubId(
  id: number | string,
) {
  return await ProxyClient.get(
    `/perfect-gym/club-opening-hours-exceptions/${id}`,
  );
}

export async function getMembershipsByClubId(clubId: number | string) {
  return await ProxyClient.get(`/memberships/${clubId}`);
}

export async function postSignUp(formData: SignUpData) {
  return await ProxyClient.post('/signup', formData);
}

export async function getFriendsConfirmationCode(code: string) {
  return await ProxyClient.get(`/friend-code/${code}`);
}

export async function getAddressByPostalCode(
  postcode: string,
  houseNumber: string,
  addition: string,
) {
  return await ProxyClient.get(
    `/postcode-nl/${postcode}/${houseNumber}/${addition ?? ''}`,
  );
}

export async function getDiscountsByIds(discountIds: number[]) {
  return await ProxyClient.get<Discount[]>(
    `/perfect-gym/discounts?isActive=1&withClubs=1&discountIds=${discountIds.join(
      ',',
    )}`,
  );
}

export async function getPaymentsByIds(paymentPlanIds: number[]) {
  return await ProxyClient.get<PaymentPlan[]>(
    `/perfect-gym/contract/payments?ids=${paymentPlanIds}`,
  );
}

export async function getJobVacancies() {
  return await ProxyClient.get<IJobVacancy[]>('/homerun');
}

export function getCheckoutClubs(params?: { checkoutConfigId?: string }) {
  return ProxyClient.get<Club.MapCard[]>('/checkout/clubs', {
    params,
  }).then((res) => res.data);
}

export function getClubPage(slug: string) {
  return ProxyClient.get<IClubContentPage>(`/content/club-page/${slug}`).then(
    (res) => res.data,
  );
}
